import Typography from '@mui/material/Typography';
import * as React from 'react';

import Link from '@mui/material/Link';

import EmailIcon from '@mui/icons-material/Email';
import HouseIcon from '@mui/icons-material/House';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Box, Stack, ThemeProvider } from '@mui/material';
import logo from '../assets/vxodsgn-logo-pos.svg';

import { lighttheme } from '../themes';


const Footer = () => {

    return (
        <ThemeProvider theme={lighttheme}>
            <Stack
                direction="column"
                justifyContent="space-evenly"
                alignItems="center"
                alignContent='center'
                spacing={2}
                p={4}
                sx={{ minHeight: "100px", backgroundColor: "#fff", position: "relative" }}
            >
                <Box >
                    <Link href="https://vxodesign.se" target="_blank"><img src={logo} alt="VXO Design Logo" style={{ width: "240px" }} /></Link>
                </Box>
                <Stack sx={{ alignContent: 'center' }}
                    direction={{ xs: "column", md: "row" }}
                    spacing={2}
                    justifyContent="space-evenly"
                    alignItems="center"
                    alignContent='center' >
                    <Stack direction="row" alignItems="center" spacing={0.5} >
                        <EmailIcon fontSize="12px" />
                        <Link sx={{ fontSize: "0.8em", color: "#333333" }} href="mailto:hello@vxodesign.se">hello@vxodesign.se</Link>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={.5} >
                        <PhoneIphoneIcon fontSize="12px" />
                        <Link sx={{ fontSize: "0.8em", color: "#333333" }} href="tel:+46708842183">070-8842183</Link>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={.5} >
                        <HouseIcon fontSize="12px" />
                        <Typography sx={{ fontSize: "0.9em", color: "#333333" }}>Org.nr: 559370-5022</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={0.5} >
                        <VerifiedUserIcon fontSize="12px" />
                        <Link sx={{ fontSize: "0.8em", color: "#333333" }} href="/integrity">Integritet</Link>
                    </Stack>
                </Stack>
            </Stack>
        </ThemeProvider>
    );
};
export default Footer;
