import React, { useEffect } from 'react';

const MetaTagsHelper = ({ prismicPage }) => {
    useEffect(() => {
        const title = prismicPage.data.meta_title
        const description = prismicPage.data.meta_description
        const keywords = prismicPage.data.meta_keywords

        if (title) document.title = title;
        if(description) updateMetaTag('description', description);
        if(keywords) updateMetaTag('keywords', keywords);
    }, [prismicPage]);

    const updateMetaTag = (name, content) => {
        let element = document.querySelector(`meta[name="${name}"]`);
        if (!element) {
            element = document.createElement('meta');
            element.setAttribute('name', name);
            document.head.appendChild(element);
        }
        element.setAttribute('content', content);
    };

    return null;
};

export default MetaTagsHelper;