import { Box, ThemeProvider } from "@mui/material";
import { RPMText } from "./subComponents/RPMText";
import { generateTheme } from "../themes";
import { RPMButton } from "./subComponents/RPMButton";


const TopSubpageSlice = ({ sliceData, color = "text.primary", direction = "row" }) => {

    const themeName = sliceData.color_theme ?? "monotheme";

    const theme = generateTheme(themeName);

    const getButtonLink = (linkData) => {
        if (!linkData) return null;
        if (linkData.includes('#')) {
            return { url: "#" + linkData.split("#")[1], type: "internal" };
        } else {
            return { url: linkData };
        }
    }

    const buttonLink = getButtonLink(sliceData.link.url);

    return (
        <ThemeProvider theme={theme}>

            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", pt: "6rem", pb: "2rem", backgroundColor: "background.default" }}>
                <RPMText priscmicField={sliceData.title1} variant="h2" sx={{ textAlign: "center" }} elementRenderType="h1" />
                <RPMText priscmicField={sliceData.subtitle} variant="h2" elementRenderType={"h2"}
                    sx={{
                        pl: "1rem",
                        color: color,
                        letterSpacing: "0.00938em",
                        lineHeight: "1.167",
                        fontSize: '1rem',
                        fontFamily: "Kanit, Roboto, Helvetica, Arial, sans-serif",
                        textTransform: "inherit",
                        fontWeight: 400,
                        marginTop: "0rem",
                        fontSize:
                        {
                            xs: "1.21rem",
                            sm: "1.9rem",
                            md: "1.94rem",
                        },
                        textAlign: "left",
                        alignContent: "flex-start",
                    }} />

                {buttonLink && (
                    <Box sx={{ px: { xs: "0", sm: "1rem" }, pt: "0", color: "color" }}>
                        <RPMButton link={buttonLink} linktext={sliceData?.linktext[0].text} size="large" type="text" />
                    </Box>
                )}
            </Box>
        </ThemeProvider >
    );
}

export default TopSubpageSlice;