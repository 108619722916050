import { createTheme } from '@mui/material/styles';

const basetheme = createTheme({})

const BLACK = '#1c1c1c'
const BLACKBG = '#1c1c1c'
const WHITE = '#ffffff'
const WHITEBG = '#F3F4F7'
const WHITEBLUE = '#DDE0E8'
const GREY = '#666666'

const GREY0 = '#FFFFFF'
const GREY50 = '#F3F4F7'
const GREY100 = '#EAECF0'
const GREY150 = '#e1e1e6'
const GREY200 = '#D1D5DB'
const GREY300 = '#9CA3AF'
const GREY400 = '#6B7280'
const GREY500 = '#4B5563'
const GREY600 = '#374151'
const GREY700 = '#1F2937'
const GREY800 = '#111827'
const GREY900 = '#16121C'
const GREY1000 = '#000000'

const ACCENT50 = '#F5F3FF'
const ACCENT100 = '#E0D7FF'
const ACCENT200 = '#D1C4FF'
const ACCENT300 = '#C4B5FD'
const ACCENT400 = '#928AFF'
const ACCENT500 = '#5C33D7'
const ACCENT600 = '#4C1D95'
const ACCENT700 = '#340E5A'
const ACCENT800 = '#1E0A30'
const ACCENT900 = '#0F0520'

const SPACEY_BRIGHT_GRADIENT = 'linear-gradient(180deg, #FFFFFF 0%, #DCEDF1 100%)'

const FORTNOX_GREEN = '#013823'
const FORTNOX_GREEN_LIGHT = '#D2FFDF'
const FORTNOX_YELLOW = '#FFCA00'
const FORTNOX_YELLOW_MEDIUM = '#FEDF7D'
const FORTNOX_YELLOW_LIGHT = '#FEF0BF'

const BACKGROUND_GRADIENT = 'linear-gradient(180deg, #FFFFFF 0%, #E5E7EB 100%)'

const LINK = '#0f3d81'
const LINK_HOVER = '#58a3e2'
const LINK_VISITED = '#0f3d81'

const LINK_DARK = '#aaccff'
const LINK_DARK_HOVER = '#ccddddff'
const LINK_DARK_VISITED = '#aaaabb'

export const DARK_WHITE = GREY100;
const ACCENT = '#5C33D7'
const ACCENTBG = '#5C33D7'
const ACCENTINTENSE = '#9051ff'

const ACCENTBGBRIGHT = '#5C33D777'

const ACCENTBGALT = 'rgba(110, 64, 201, 0.1)'
const ALT = '#C94092'
const ALTBG = 'rgba(201, 64, 146, 0.29)'
const TABLEBG_DARK = 'rgba(86, 86, 86, 0.11)'
const TABLEBG_LIGHT = 'rgba(0, 0, 0, 0.03)'
const ACCENT_DARK = '#009adc'

const createResponsiveFontSizes = (sizes) => {
    const scale = 1.2; // Define your scale factor
    const defaultSize = '1rem'; // Default size if no sizes are provided

    // Utility function to calculate scaled size
    const calculateSize = (baseSize, factor) => `${parseFloat(baseSize) * factor}rem`;

    // Determine the base size from the provided sizes
    const baseSize = sizes.xs || sizes.sm || sizes.md || sizes.lg || defaultSize;
    const baseBreakpoint = Object.keys(sizes)[0] || 'xs';

    // Calculate scale factor based on the provided base breakpoint
    const scaleFactor = {
        xs: 1 / (scale * scale * scale),
        sm: 1 / (scale * scale),
        md: 1 / scale,
        lg: 1,
    };

    return {
        [basetheme.breakpoints.up('xs')]: { fontSize: sizes.xs || calculateSize(baseSize, scaleFactor.xs) },
        [basetheme.breakpoints.up('sm')]: { fontSize: sizes.sm || calculateSize(baseSize, scaleFactor.sm) },
        [basetheme.breakpoints.up('md')]: { fontSize: sizes.md || calculateSize(baseSize, scaleFactor.md) },
        [basetheme.breakpoints.up('lg')]: { fontSize: sizes.lg || baseSize },
    };
};

const TYPOGRAPHY = {
    h1: {
        fontWeight: 500,
        fontFamily: 'Kanit, sans-serif',
        ...createResponsiveFontSizes({
            xs: "3.8rem",
            sm: "5.5rem",
            md: "6rem",
            lg: "6rem",
        })
    },
    h1Bold: {
        fontWeight: 800,
        fontFamily: 'Kanit, sans-serif',
        lineHeight: 0.7,
        textTransform: "uppercase",
        ...createResponsiveFontSizes({
            xs: "3.8rem",
            sm: "5.5rem",
            md: "6rem",
            lg: "6rem",
        })
    },
    h2: {
        fontWeight: 500,
        fontFamily: 'Kanit, sans-serif',
        lineHeight: 0.9,
        marginBottom: "0.5rem",
        ...createResponsiveFontSizes({ xs: '2.8rem', sm: '3.2rem', md: '3.4rem', lg: '3.6rem' })
    },
    h2Bold: {
        fontSize: '3rem',
        fontWeight: 800,
        fontFamily: 'Kanit, sans-serif',
        lineHeight: 0.7,
        marginBottom: "0.5rem",
        textTransform: "uppercase",
        ...createResponsiveFontSizes({ xs: '2.8rem', sm: '3.4rem', md: '3.6rem', lg: '4.8rem' })
    },
    h3: {
        fontWeight: 500,
        fontFamily: 'Kanit, sans-serif',
        lineHeight: 0.9,
        marginBottom: "0.5rem",
        ...createResponsiveFontSizes({ xs: '2.2rem', sm: '2.2rem', md: '2.3rem', lg: '2.5rem' })
    },
    h4: {
        fontWeight: 500,
        fontFamily: 'Kanit, sans-serif',
        lineHeight: 0.9,
        marginBottom: "0.5rem",
        ...createResponsiveFontSizes({ xs: '1.2rem', sm: '1.2rem', md: '1.3rem', lg: '1.4rem' })
    
    },
    h5: {
        fontWeight: 500,
        fontFamily: 'Kanit, sans-serif',
        lineHeight: 0.9,
        marginBottom: "0.5rem",
        fontSize: '1rem',
    },
    p: {
        fontSize: '1rem',
        fontWeight: 400,
        marginBottom: "1rem",
    },

    fontFamily: 'Roboto, sans-serif',
    fontSize: 16,

}


const darktheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: ACCENT,
            contrastText: WHITE,
            contrastText2: DARK_WHITE,

        },
        secondary: {
            main: WHITE,
            contrastText: BLACK,
        },
        background: {
            default: BLACKBG,
            secondary: ACCENTBG,
            secondaryalt: ACCENTBGALT,
            alt: ALT,
            altbg: ALTBG,
            tablebg_dark: TABLEBG_DARK,
            tablebg_light: TABLEBG_LIGHT,
            paper: "#7d8790",
        },
        text: {
            primary: WHITE,
            secondary: '#7d8790',
            disabled: '#7d8790',
            icon: '#ffffff',
            dark: '#11151a',
            highlight: ACCENT
        },
        action: {
            active: '#ff0000',
            hover: '#58a3e2',
            selected: '#ff00ff',
            focus: '#ff00ff',
            disabled: '#ff0000',
        }
    },
    typography: TYPOGRAPHY
});

const lighttheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: ACCENT,
            mainIntense: ACCENTINTENSE,
            contrastText: WHITE,
            contrastText2: DARK_WHITE,
            alt: ALT
        },
        secondary: {
            main: GREY,
            contrastText: BLACK
        },
        background: {
            default: WHITE,
            dark: BLACKBG,
            secondary: DARK_WHITE,
            secondaryalt: ACCENTBGALT,
            highlight: ACCENTBGBRIGHT,
            alt: ALT,
            altbg: ALTBG,
            paper: GREY,
            tablebg_dark: TABLEBG_DARK,
            tablebg_light: TABLEBG_LIGHT,
        },
        text: {
            primary: BLACK,
            secondary: '#7d8790',
            disabled: '#7d8790',
            icon: '#ffffff',
            dark: '#11151a',
            highlight: "#fae246"
        },
        action: {
            active: '#7d8790',
            hover: '#58a3e2',
            selected: '#ff00ff',
            focus: '#ff00ff',
            disabled: '#464c54',
        }
    },
    typography: TYPOGRAPHY
});

const accenttheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: WHITE,
            contrastText: WHITE,
        },
        secondary: {
            main: WHITE,
            contrastText: WHITE
        },
        background: {
            default: ACCENTBG,
            secondary: BLACK,
            alt: ALT,
            altbg: ALTBG,
            paper: BLACK,
            tablebg_dark: TABLEBG_DARK,
            tablebg_light: TABLEBG_LIGHT,
        },
        text: {
            primary: WHITE,
            secondary: '#7d8790',
            disabled: '#7d8790',
            icon: '#ffffff',
            dark: '#11151a',
            highlight: "#fae246",
            paper: WHITE
        },
        action: {
            active: '#7d8790',
            hover: '#58a3e2',
            selected: '#ff00ff',
            focus: '#ff00ff',
            disabled: '#464c54',
        },
        paper: {
            background: "red"
        }

    },
    typography: TYPOGRAPHY
});

const whitetheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: BLACK,
            mainIntense: ACCENTINTENSE,
            contrastText: WHITE,
            contrastText2: DARK_WHITE,
            alt: ALT
        },
        secondary: {
            main: GREY,
            contrastText: BLACK
        },
        background: {
            default: WHITE,
            dark: BLACKBG,
            secondary: DARK_WHITE,
            secondaryalt: ACCENTBGALT,
            highlight: ACCENTBGBRIGHT,
            alt: ALT,
            altbg: ALTBG,
            paper: WHITE,
            tablebg_dark: TABLEBG_DARK,
            tablebg_light: TABLEBG_LIGHT,
        },
        text: {
            primary: BLACK,
            secondary: '#7d8790',
            disabled: '#7d8790',
            icon: '#ffffff',
            dark: '#11151a',
            highlight: "#fae246"
        },
        action: {
            active: '#7d8790',
            hover: '#58a3e2',
            selected: '#ff00ff',
            focus: '#ff00ff',
            disabled: '#464c54',
        }
    },
    typography: TYPOGRAPHY
});

const blacktheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: WHITE,
            mainIntense: WHITE,
            contrastText: WHITE,
            contrastText2: DARK_WHITE,
            alt: ALT
        },
        secondary: {
            main: GREY,
            contrastText: BLACK
        },
        background: {
            default: BLACK,
            dark: BLACK,
            secondary: BLACK,
            secondaryalt: BLACK,
            highlight: WHITE,
            alt: ALT,
            altbg: ALTBG,
            paper: WHITE,
            tablebg_dark: TABLEBG_DARK,
            tablebg_light: TABLEBG_LIGHT,
        },
        text: {
            primary: WHITE,
            secondary: '#CCCCCC',
            disabled: '#7d8790',
            icon: '#ffffff',
            dark: '#11151a',
            highlight: "#fae246"
        },
        action: {
            active: '#7d8790',
            hover: '#58a3e2',
            selected: '#ff00ff',
            focus: '#ff00ff',
            disabled: '#464c54',
        }
    },
    typography: TYPOGRAPHY
});

const monotheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: BLACK,
            mainIntense: ACCENTINTENSE,
            contrastText: WHITE,
            contrastText2: DARK_WHITE,
            alt: ALT
        },
        secondary: {
            main: GREY,
            contrastText: BLACK
        },
        background: {
            default: WHITE,
            dark: BLACKBG,
            secondary: DARK_WHITE,
            secondaryalt: ACCENTBGALT,
            highlight: ACCENTBGBRIGHT,
            alt: ALT,
            altbg: ALTBG,
            paper: GREY,
            tablebg_dark: TABLEBG_DARK,
            tablebg_light: TABLEBG_LIGHT,
        },
        text: {
            primary: BLACK,
            secondary: '#333333',
            disabled: '#7d8790',
            icon: '#ffffff',
            dark: '#11151a',
            highlight: "#fae246"
        },
        action: {
            active: '#7d8790',
            hover: '#58a3e2',
            selected: '#ff00ff',
            focus: '#ff00ff',
            disabled: '#464c54',
        }
    },
    typography: TYPOGRAPHY
});

const greentheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: FORTNOX_YELLOW,
            mainIntense: ACCENTINTENSE,
            contrastText: BLACK,
            contrastText2: DARK_WHITE,
            alt: ALT
        },
        secondary: {
            main: GREY,
            contrastText: BLACK
        },
        background: {
            default: FORTNOX_GREEN,
            dark: BLACKBG,
            secondary: DARK_WHITE,
            secondaryalt: ACCENTBGALT,
            highlight: ACCENTBGBRIGHT,
            alt: ALT,
            altbg: ALTBG,
            paper: WHITE,
            tablebg_dark: TABLEBG_DARK,
            tablebg_light: TABLEBG_LIGHT,
        },
        text: {
            primary: WHITE,
            secondary: '#7d8790',
            disabled: '#7d8790',
            icon: '#ffffff',
            dark: '#11151a',
            highlight: "#fae246"
        },
        action: {
            active: '#7d8790',
            hover: '#58a3e2',
            selected: '#ff00ff',
            focus: '#ff00ff',
            disabled: '#464c54',
        }
    },
    typography: TYPOGRAPHY
});

const yellowtheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: FORTNOX_YELLOW_MEDIUM,
            mainIntense: ACCENTINTENSE,
            contrastText: BLACK,
            contrastText2: DARK_WHITE,
            alt: ALT
        },
        secondary: {
            main: FORTNOX_YELLOW_LIGHT,
            contrastText: BLACK
        },
        background: {
            default: FORTNOX_GREEN,
            dark: BLACKBG,
            secondary: DARK_WHITE,
            secondaryalt: ACCENTBGALT,
            highlight: ACCENTBGBRIGHT,
            alt: ALT,
            altbg: ALTBG,
            paper: WHITE,
            tablebg_dark: TABLEBG_DARK,
            tablebg_light: TABLEBG_LIGHT,
        },
        text: {
            primary: WHITE,
            secondary: '#7d8790',
            disabled: '#7d8790',
            icon: '#ffffff',
            dark: '#11151a',
            highlight: "#fae246"
        },
        action: {
            active: '#7d8790',
            hover: '#58a3e2',
            selected: '#ff00ff',
            focus: '#ff00ff',
            disabled: '#464c54',
        }
    },
    typography: TYPOGRAPHY
});


export const generateTheme = (themeName) => {

    const defaults = {
        components: {
            MuiTypography: {
                defaultProps: {
                    color: 'primary'
                }
            }
        }
    }

    let light_theme = {
        palette: {
            mode: 'light',
            primary: {
                main: ACCENT500,
                contrastText: WHITE,
            },
            secondary: {
                main: BLACK,
                contrastText: WHITE
            },
            background: {
                default: WHITE,
                secondary: DARK_WHITE,
                paper: GREY100,
                black: GREY1000,
                white: GREY0,
                light: GREY50,
                dark: GREY900,
                border: GREY150
            },
            text: {
                primary: BLACK,
                link: LINK,
                link_hover: LINK_HOVER,
                link_visited: LINK_VISITED,
            }
        },
        typography: TYPOGRAPHY
    };

    let dark_theme = {
        palette: {
            mode: 'dark',
            primary: {
                main: ACCENT500,
                contrastText: WHITE,
            },
            secondary: {
                main: WHITE,
                contrastText: BLACK
            },
            background: {
                default: GREY900,
                secondary: BLACK,
                paper: GREY1000,
                black: GREY1000,
                white: GREY0,
                light: GREY50,
                dark: GREY900,
                border: GREY150
            },
            text: {
                primary: WHITE,
                link: LINK_DARK,
                link_hover: LINK_DARK_HOVER,
                link_visited: LINK_DARK_VISITED,
            }
        },
        typography: TYPOGRAPHY
    };

    let theme = light_theme;

    theme = { ...theme, ...defaults }


    switch (themeName) {
        case "White":
            theme = light_theme;
            theme.palette.background.default = WHITE;
            break
        case "Bright":
            theme = light_theme;
            theme.palette.background.default = GREY50;
            theme.palette.background.secondary = GREY200;
            theme.palette.background.paper = GREY0;
            break
        case "Semi-bright":
            theme = light_theme;
            theme.palette.background.default = GREY100;
            theme.palette.background.secondary = GREY200;
            theme.palette.background.paper = GREY0;
            break
        case "Semi-dark":
            theme = light_theme;
            theme.palette.background.default = GREY200;
            theme.palette.background.secondary = GREY400;
            theme.palette.background.paper = GREY0;
            break
        case "Dark":
            theme = dark_theme;
            break
        case "Colorful Yellow":
            theme = light_theme;
            theme.palette.primary.main = FORTNOX_GREEN;
            theme.palette.background.default = FORTNOX_YELLOW_MEDIUM;
            theme.palette.background.secondary = FORTNOX_YELLOW_LIGHT;
            break
        case "Colorful Green":
            theme = dark_theme;
            theme.palette.background.default = FORTNOX_GREEN;
            theme.palette.primary.main = FORTNOX_YELLOW;
            theme.palette.primary.contrastText = BLACK;
            break
        case "Spacey bright":
            theme = light_theme;
            theme.palette.background.default = SPACEY_BRIGHT_GRADIENT;
            theme.palette.background.secondary = GREY100;
            theme.palette.background.paper = GREY0;
            break
        default:
            theme = light_theme;
    }

    return createTheme(theme);
}

export { darktheme, lighttheme, accenttheme, whitetheme, blacktheme, monotheme, greentheme, yellowtheme }

