import Typography from '@mui/material/Typography';
import * as React from 'react';

import Button from '@mui/material/Button';

import { Alert, Box, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, Link, MenuItem, Radio, RadioGroup, Select, Snackbar, Stack, TextField, ThemeProvider } from '@mui/material';
import { PrismicRichText, usePrismicDocumentByUID } from '@prismicio/react';
import { whitetheme } from '../themes';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import gsap from 'gsap';
import TopNavigation from '../components/TopNavigation';
import Footer from '../components/Footer';
import { useEffect } from 'react';

/**
 * 
 * Route /request from index.js
 */

const TestCard = () => {
  const [formPosted, setFormPosted] = React.useState(false);
  const [formError, setFormError] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("Tack för din medverkan!");
  let token = null

  const [emailError, setEmailError] = React.useState("");
  const [phoneNumberError, setPhoneNumberError] = React.useState("");
  const [altPhoneNumberError, setAltPhoneNumberError] = React.useState("");
  const [companyUrlError, setCompanyUrlError] = React.useState("");
  const [personalSiteError, setPersonalSiteError] = React.useState("");

  const [formValid, setFormValid] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const containerRef = React.useRef(null);
  const readmoreRef = React.useRef(null);

  const [direction, setDirection] = React.useState("column");

  const theme = whitetheme

  //const [requestPage] = usePrismicDocumentByUID('homepage', 'the-start-page')

  /*
  React.useEffect(() => {
    if (requestPage) {
      console.log(requestPage)

    }
  }, [requestPage])
*/

  /*
  const handleSubmit = (event) => {
    event.preventDefault();
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneNumberRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    setEmailError(!emailRegex.test(values.email));
    setPhoneNumberError(!phoneNumberRegex.test(values.phoneNumber));
  };
  */

  const referer = new URLSearchParams(window.location.search).get("r");

  console.log("referer")
  console.log(referer)


  const [formValues, setFormValues] = React.useState(
    {
      w_country_name: { value: 'Sweden', component: 'hidden' },
      r_code: { value: referer ? referer : '', component: 'hidden' },
      companyInfo: { name: 'Företagsinformation', minWidth: 12, component: 'section' },
      c_name: { value: '', error: false, required: true, name: 'Företag / organisation / förening', minWidth: 12, component: 'input' },
      c_url: { value: '', error: false, errorText: "Felaktigt format på webbplatsen", required: true, name: 'Webbplats (används som grund för kortdesign)', validation: 'url', minWidth: 12, component: 'input' },
      w_visit_city: { value: '', error: false, required: true, name: 'Postort', minWidth: 12, component: 'input' },
      w_visit_zip: { value: '', error: false, errorText: "Felaktigt format på postnumret", required: false, name: 'Postnummer', validation: 'postcode', minWidth: 3, component: 'input' },
      w_visit_street: { value: '', error: false, required: false, name: 'Address', minWidth: 9, component: 'input' },
      c_email: { value: '', error: false, errorText: "Felaktigt format på e-postadressen", required: false, name: 'E-post', validation: 'email', minWidth: 12, component: 'input' },
      c_phone: { value: '', error: false, errorText: "Felaktigt format på telefonnumret", required: false, name: 'Telefonnummer', validation: 'phone', minWidth: 12, component: 'input' },
      personalInfo: { name: 'Personlig information', minWidth: 12, component: 'section' },
      p_first_name: { value: '', error: false, required: true, name: 'Förnamn', minWidth: 6, component: 'input' },
      p_last_name: { value: '', error: false, required: true, name: 'Efternamn', minWidth: 6, component: 'input' },
      p_email: { value: '', error: false, errorText: "Felaktigt format på e-postadressen", required: true, name: 'E-post', validation: 'email', minWidth: 12, component: 'input' },
      p_title: { value: '', error: false, required: false, name: 'Jobbtitel', minWidth: 6, component: 'input' },
      p_department: { value: '', error: false, required: false, name: 'Avdelning', minWidth: 6, component: 'input' },

      p_mobile_phone: { value: '', error: false, errorText: "Felaktigt format på telefonnumret", required: false, name: 'Telefonnummer', validation: 'phone', minWidth: 12, component: 'input' },
      p_other_phone: { value: '', error: false, errorText: "Felaktigt format på telefonnumret", required: false, name: 'Alternativt telefonnummer', validation: 'phone', minWidth: 12, component: 'input' },

      p_url: { value: '', error: false, required: false, name: 'Personlig webbplats (ex. linkedin', validation: 'url', minWidth: 12, component: 'input' },
      p_message: { value: '', error: false, required: false, name: 'Meddelande (valfritt)', minWidth: 12, component: 'textarea' },
      p_phone_type_name: { value: 'N/A', error: false, errorText: "Felaktigt format på webbplatsen", required: false, name: 'Telefontyp', minWidth: 12, component: 'select', options: [{ name: '- Välj -', value: "N/A" }, { name: "iPhone", value: 'iphone' }, { name: "Android", value: 'android' }] },

    }
  );

  useEffect(() => {
    validateForm()
  }, [formValues])

  // new useEffect
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://www.google.com/recaptcha/api.js?render=6LccvMQjAAAAAI7wLF3R_hrVj9ayNKwFGCPm06rw';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  const handleSubmit = async (event) => {

    console.log('%csubmit', 'color: red')

    //prepare form raw data

    const cleanedData = Object.keys(formValues).reduce((acc, key) => {
      if (formValues[key].component === 'section') return acc
      if (formValues[key].component === 'hidden') return { ...acc, [key]: formValues[key].value }
      return { ...acc, [key]: formValues[key].value }
    }, {})

    console.log(cleanedData)



    const form = new FormData(event.currentTarget);

    console.log(form)



    //grecaptcha.ready(function() {
    // Add your logic to submit to your backend server here.
    //document.getElementById("token").value = token;
    //const formData = new FormData(form);
    //email(formData);
    // });
    //});

    event.preventDefault();
    window.grecaptcha.ready(function () {
      window.grecaptcha.execute('6LccvMQjAAAAAI7wLF3R_hrVj9ayNKwFGCPm06rw', { action: 'submit' }).then(async function (token) {
        // Send form value as well as token to the server

        try {

          form.append('token', token);
          event.preventDefault();
          await fetch("https://cardcam.io/ccmail/freecard.php", {
            method: "post",
            body: form
          }).then(async (response) => {
            if (response.status === 200) {
              let data = await response.text();
              try {
                setSuccessMessage(JSON.parse(data).message)
              }
              catch (e) {

              }
              setFormPosted(true);
            } else {

              setFormError(true);
              setFormPosted(false);
            }
          })
        } catch (error) {
          setFormError(true);
          setFormPosted(false);
        }

      });
    });
  }

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    /*
    console.log("%c------------: ", "color: red")

    console.log("handleInputChange:")
    console.log("name ", name)
    console.log("value ", value)
    console.log("target ", formValues[name])

    validate(formValues[name], name)
    */


    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value: value
      }
    });


  }

  const inputStyle = {

  };

  const handleSetError = (name, error) => {
    console.log("handleSetError: " + name + " " + error)
    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        error: error
      }
    });

    console.log("IsError?")
    console.log(formValues[name])
  }

  const validate = (item, itemName) => {
    console.log("validate: " + itemName)
    console.log(item)
    console.log(item.validation)
    if (item.validation === 'email') {
      validateEmail(item, itemName)
    }
    else if (item.validation === 'phone') {
      validatePhone(item, itemName)
    }
    else if (item.validation === 'url') {
      validateUrl(item, itemName)
    }
    else if (item.validation === 'postcode') {
      validatePostcode(item, itemName)
    }
  }


  const validateEmail = (item, itemName) => {
    if (item.value === '') {
      handleSetError(itemName, false)
      return;
    }
    // console.log("validateEmail: " + item.value)
    let valid = (String(item.value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ))



    handleSetError(itemName, !valid)
  }

  const validateUrl = (item, itemName) => {
    if (item.value === '') {
      handleSetError(itemName, false)
      return;
    }

    //console.log("validateUrl: ", item)
    const urlPattern = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;

    let valid = (String(item.value)
      .toLowerCase()
      .match(urlPattern))


    handleSetError(itemName, !valid)

  };

  const validatePhone = (item, itemName) => {
    if (item.value === '') {
      handleSetError(itemName, false)
      return;
    }

    //console.log("validatePhone: " + item.value)
    // test for valid phone number
    const phonePattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    let valid = (String(item.value)
      .toLowerCase()
      .match(phonePattern))

    handleSetError(itemName, !valid)

  };

  const validatePostcode = (item, itemName) => {
    console.log("%cvalidatePostcode: ", "color: red")
    console.log("value: " + item.value)
    console.log("name: " + itemName)
    if (item.value === '') {
      handleSetError(itemName, false)
      return;
    }

    console.log("validatePostcode: " + item.value)
    // test for valid post code
    const postcodePattern = /^\d{5}$/;
    let valid = (String(item.value)
      .toLowerCase()
      .match(postcodePattern))

    console.log("valid: " + valid)
    console.log("///////////////////")

    handleSetError(itemName, !valid)

  };

  const validateForm = () => {
    let valid = true;
    let error = false;
    for (const [key, value] of Object.entries(formValues)) {
      if (value.required && value.value === '') {
        //console.log("required field missing: " + key)
        valid = false;
        //handleSetError(key, true)
      }
      if (value.error && value.value !== '') {
        //console.log("error field: " + key)
        valid = false;
        error = true;
      }

    }

    setIsError(error);
    setFormValid(valid);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setFormError(false);
  };

  const generateFormFields = () => {
    let formFields = [];
    for (const [key, value] of Object.entries(formValues)) {
      if (value.component === 'input') {
        formFields.push(
          <Grid item xs={12} md={value.minWidth} key={key}>

            <TextField
              id={key}
              variant="standard"
              name={key}
              fullWidth
              label={value.name}
              size='small'
              type="text"
              sx={{
                '& .MuiInputBase-root': {
                  backgroundColor: value.error ? '#ff00000a' : value.required ? "#5C33D71a" : 'grey',
                }
              }}
              value={value.value}
              helperText={value.error ? value.errorText : ''}
              onChange={(e) => { handleInputChange(e); }}
              required={value.required}
              error={value.error}

              onBlur={() => validate(value, key)}
            />
          </Grid>
        )
      } else if (value.component === 'textarea') {
        formFields.push(
          <Grid item xs={12} md={value.minWidth} key={key}>
            <TextField
              id={key}
              variant="standard"
              name={key}
              fullWidth
              label={value.name}
              size='small'
              type={value.type}
              value={value.value}

              required={value.required}
              error={value.error}
              onChange={handleInputChange}
              multiline
            />
          </Grid>

        )
      } else if (value.component === 'select') {
        formFields.push(
          <Grid item xs={12} md={value.minWidth} key={key}>
            <FormControl variant="standard" fullWidth size='small'>
              <InputLabel id={key}>{value.name}</InputLabel>
              <Select
                labelId={key}
                id={key}
                name={key}
                value={value.value}
                onChange={handleInputChange}
                label={value.name}
                required={value.required}
                error={value.error}
              >
                {value.options.map((option, index) => {
                  return (
                    <MenuItem key={index} value={option.value}>{option.name}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>

          </Grid>
        )
      }
      else if (value.component === 'section') {
        formFields.push(
          <Grid item xs={12} md={value.minWidth} key={key} mt={4}>
            <Typography variant="h6" component="h2" gutterBottom>
              {value.name}
            </Typography>
          </Grid>
        )
      }
      else if (value.component === "hidden") {
        formFields.push(
          <input type="hidden" name={key} value={value.value} key={key} />
        )
      }
    }

    return formFields;
  }



  if (!formPosted) {
    //console.log("formPosted: " + formPosted)
    return (
      <ThemeProvider theme={theme}>
        <TopNavigation />
        <Stack ref={containerRef} id={"contact"} direction="column" spacing={2} sx={{ justifyContent: "space-between", alignItems: "flex-start", backgroundColor: "background.default" }}>
          <Box className="headline" display="flex" sx={{ mt: 4, width: "100%", flexDirection: "column", alignItems: "center" }}>
            <Box sx={{ width: "100%", maxWidth: "600px", px: { md: "40px", xs: "30px" } }}>
              <Typography variant={"h1"} sx={{ fontSize: { xs: "3.2em", md: "4.8em" }, lineHeight: "0.9em" }} color="text.primary">
                Ditt digitala visitkort.
              </Typography>
              <Typography pt={2} variant='h4' sx={{ fontSize: "1.4em" }} color="text.primary">
                Cardcam är ett klimatsmart visitkort för din digitala plånbok.
              </Typography>

              <Typography pt={2} sx={{ typography: { sm: 'p', xs: 'p' }, pt: 3 }} color="text.primary">
                Tack för ditt intresse för CardCam! Fyll i de uppgifter som du vill ha med på ditt kort nedan (* = obligatoriskt fält) så tar vi kontakt med dig inom kort.
              </Typography>
              <Typography pt={2} sx={{ typography: { sm: 'p', xs: 'p' } }} color="text.primary">
                Vi skapar en unik design baserat på ditt företags grafiska profil och återkommer med ett kort till dig inom kort. Har du specifika önskemål så kan du skriva dem i "Meddelande"-fältet."
              </Typography>


            </Box>
          </Box>
          <Grid container
            direction={direction}
            justifyContent="flex-start"
            alignItems="center"
            pb={6}
            sx={{ backgroundColor: "background.default" }}>
            <Grid item xs={12} md={6} display="flex" sx={{ justifyContent: { md: direction === "row" ? "flex-start" : "flex-end", xs: "center" } }}>
              <Box sx={{ maxWidth: { md: "600px", xs: "100%" } }} >
                <form onSubmit={handleSubmit}>
                  <Grid container
                    direction={"row"}
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    sx={{ backgroundColor: "background.default", px: { md: "40px", xs: "30px" } }}>

                    {generateFormFields()}



                    <Grid item xs={12} md={12} display="flex" sx={{ justifyContent: { md: direction === "row" ? "flex-end" : "flex-start", xs: "center" }, mt: 4 }}>

                      <Button variant="contained" color="primary" size="large" fullWidth type="submit" disabled={!formValid}>
                        Skicka
                      </Button>
                    </Grid>

                    {(!formValid && !isError) &&
                      <Grid item xs={12} md={12} display="flex" sx={{ justifyContent: { md: direction === "row" ? "flex-end" : "flex-start", xs: "center" } }}>
                        <Typography variant='tiny' color={theme.palette.primary.main}>Fyll i alla obligatoriska fält *</Typography>
                      </Grid>
                    }

                    {(!formValid && isError) &&
                      <Grid item xs={12} md={12} display="flex" sx={{ justifyContent: { md: direction === "row" ? "flex-end" : "flex-start", xs: "center" } }}>
                        <Typography variant='tiny' color="error">Du behöver rätta till felaktiga fält innan du kan skicka in formuläret</Typography>
                      </Grid>
                    }

                    <Snackbar open={formError} autoHideDuration={6000} onClose={handleClose}>
                      <Alert onClose={handleClose} variant="filled" severity="error" sx={{ width: '100%' }}>
                        Någonting gick fel, försök igen!
                      </Alert>
                    </Snackbar>
                    <Grid item xs={12} md={12} display="flex" sx={{ justifyContent: { md: direction === "row" ? "flex-end" : "flex-start", xs: "center" }, mt: 1 }}>

                      <Typography variant='tiny'>This site is protected by reCAPTCHA and the Google&nbsp;
                        <Link href="https://policies.google.com/privacy" target="_blank">Privacy Policy</Link> and&nbsp;
                        <Link href="https://policies.google.com/terms" target="_blank">Terms of Service</Link> apply.
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Stack>
        <Footer />
      </ThemeProvider>
    )


  }
  else {
    return (
      <ThemeProvider theme={theme}>
        <TopNavigation />

        <Grid container

          id={"contact"}
          direction={direction}
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: "100vh", backgroundColor: "background.default" }}>

          <Grid item xs={12} display="flex" px={8} py={4} sx={{ justifyContent: "center" }}>
            <Box >
              <Typography variant="h1" sx={{ fontSize: "2.5em" }} color="text.primary">Tack för ditt meddelande!</Typography>
              <Stack mt={3} direction="row" alignItems="center" spacing={2}><CheckCircleIcon color="success" size="90px" /><Typography sx={{ typography: { sm: 'h4', xs: 'h4' } }} color="text.primary">{successMessage}</Typography></Stack>
            </Box>
          </Grid>
          <Grid item xs={12} display="flex" px={8} py={4} sx={{ justifyContent: "center" }}>
            <Button variant="text" color="primary" size="large" onClick={() => { window.location.href = "/" }}>
              Gå till cardcam.io
            </Button>
          </Grid>
        </Grid>
        <Footer />

      </ThemeProvider>
    )
  }
}

export default TestCard;


