import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const CanonicalUpdater = () => {
    const location = useLocation();

    useEffect(() => {
        const canonicalLink = document.querySelector("link[rel='canonical']");
        if (canonicalLink) {
            canonicalLink.href = window.location.origin + location.pathname;
        } else {
            const newCanonicalLink = document.createElement('link');
            newCanonicalLink.rel = 'canonical';
            newCanonicalLink.href = window.location.origin + location.pathname;
            document.head.appendChild(newCanonicalLink);
        }
    }, [location]);

    return null; // This component does not render anything
};

export default CanonicalUpdater;