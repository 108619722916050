import { Button } from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
export const RPMButton = ({ link = null, action = null, linktext, type = "contained", size = "medium", sx = {} }) => {

    //sx={{ borderRadius: "2rem", px: 3, py: 2, mt: 2, width: "100%", backgroundColor: "primary", color: theme.palette.text.default }}

    const goToAnchor = (anchor) => {
        //strip the # from the anchor

        console.log("goToAnchor", anchor);
        anchor = anchor.replace('#', '');

        const element = document.getElementById(anchor);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
    }

    const containedSX = {
        borderRadius: "0.5rem",
        textTransform: "none",

        px: 3,
        py: 1.5,
        my: 1,
        "&:hover": {
            textDecoration: type === "text" ? "underline" : "none"
        },
        width: "100%",
        maxWidth: "500px"

    }

    const textSX = {
        textTransform: "none",
        fontWeight: "bold",
        "&:hover": {
            textDecoration: "underline",
            backgroundColor: "transparent"
        },
        maxWidth: "500px",
        px: 0
    }

    const outlinedSX = {
        borderRadius: "0.5rem",
        textTransform: "none",
        px: 3,
        py: 1.5,
        my: 1,
        borderColor: "text.primary",
        color: "text.primary",
        "&:hover": {
            textDecoration: type === "text" ? "underline" : "none"
        },
        width: "100%",
        maxWidth: "500px"
    }

    const defaultSx = type === "text" ? textSX : type === "outlined" ? outlinedSX : containedSX;

    console.log("defaultSx", defaultSx);

    const msx = { ...defaultSx, ...sx };

    console.log("sx", sx);

    const fixAnchorLinksOld = (link) => {
        if (!link) return null;
        if (link.includes('https://#')) {
            return link.replace('https://#', '#');
        }
        return link;
    }

    const fixAnchorLinks = (link) => {
        console.log("fixAnchorLinks", link)
        if (!link) return { navlink: null, isAnchor: false };

        let navlink = link;
        let isAnchor = false;

        if (link.includes('#')) {
            console.log("fixAnchorLinks is anchor", link)
            navlink = link.split("#")[1];
            isAnchor = true;
        }
        return { navlink, isAnchor };
    }

    const { navlink, isAnchor } = fixAnchorLinks(link?.url);

    console.log("navlink", navlink, isAnchor);


    return (
        <Button
            variant={type}
            href={ isAnchor ? null : navlink}
            onClick={ isAnchor ? () => goToAnchor(navlink) : action ? action() : null}
            target={link?.target ? link?.target : "_self"}
            size={size}
            sx={msx}
        >
            {linktext}{link?.target === "_blank" ? <OpenInNewIcon sx={{ ml: 1, fontSize: "1.2rem" }} /> : <KeyboardDoubleArrowRightIcon sx={{ ml: 0.5, mt: 0.3, fontSize: "1.2rem" }} />}
        </Button>
    );
}