import { CssBaseline, GlobalStyles } from '@mui/material';
import { PrismicProvider } from '@prismicio/react';
import { PrismicToolbar } from '@prismicio/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import App from './App';
import CanonicalUpdater from './components/utils/CanonicalHelper';
import './index.css';
import { client, repositoryName } from './prismic';
import reportWebVitals from './reportWebVitals';
import TestCard from './routes/TestCard';

import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";
import "@fontsource/kanit/300.css";
import "@fontsource/kanit/400.css";
import "@fontsource/kanit/500.css";
import "@fontsource/kanit/900.css";

if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <PrismicProvider client={client}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <BrowserRouter>
      <CanonicalUpdater /> 
        <Routes>
          <Route path='/' element={<App />} />
          <Route path='/request' element={<TestCard />} />
          <Route path='/thank-you' element={<App pageId="form-thank-you" />} />
          <Route path='/small-organization' element={<App pageId="small-organization" />} />
          <Route path='/integrity' element={<App pageId="cookies-and-integrity" />} />
          <Route path="*" element={<App />} />
        </Routes>
      </BrowserRouter>
      <PrismicToolbar repositoryName={repositoryName} />
    </PrismicProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
