import { Box, Button, Stack, ThemeProvider, Typography } from '@mui/material';
import { gsap } from 'gsap';
import * as React from 'react';

import { generateTheme } from '../themes';


import apple from '../assets/apple_wallet_icon.svg';
import android from '../assets/google_wallet_icon.svg';

import { useEffect, useRef } from 'react';

import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { RPMText } from './subComponents/RPMText';
import { RPMButton } from './subComponents/RPMButton';

gsap.registerPlugin(ScrollTrigger);

const TopHeroSlice = ({ sliceData, color = "primary.main", direction = "row" }) => {

  // Create a ref to store the current position of the "bgphones" image
  const awRef = useRef(null);
  const ccRef = useRef(null);
  const aaRef = useRef(null);
  const topimgContainerRef = useRef(null);
  const txtHeaderRef = useRef(null);
  const txtSubHeaderRef = useRef(null);

  let heroImage = sliceData.image.url;
  const heroImageAspectRatio = sliceData?.image?.dimensions?.width / sliceData?.image?.dimensions?.height;

  console.log("heroImageAspectRatio", heroImageAspectRatio);
  const [imageLoaded, setImageLoaded] = React.useState(false);

  // Base64 encoded placeholder image
  const placeholderImage = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

  // This function is called when the main image loads
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  console.log("-->sliceData: ", sliceData);

  const themeName = sliceData.color_theme ?? "monotheme";

  const containerRef = useRef(null);

  const currentTopPos = useRef(0);

  console.log("generate theme: " + themeName);

  const theme = generateTheme(themeName);

  console.log("theme: ", theme);


  const Tagline = () => {

    console.log("Tagline: ", sliceData);

    if (!sliceData?.tagline || sliceData?.tagline?.length <= 0) {
      console.log("Tagline NULL: ", sliceData.tagline);
      console.log("Tagline NULL: ", sliceData?.tagline?.length);
      return null;
    }

    const tagline = sliceData.tagline
    const taglineImage = sliceData.tagline_image?.url ?? null

    if (taglineImage) {

      return (
        <Box sx={{ px: { xs: "0", sm: "1rem" }, pt: "1rem", color: color }}>
          <div style={{ borderRadius: "0.3rem", width: "100%", height: "100%", color: "#333", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "stretch", fontSize: "16px", fontWeight: "500", padding: "0.8rem", gap: "1rem", minHeight: "84px", marginTop: "1rem", maxWidth: "500px" }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
              <img src={taglineImage} alt="Tagline image" style={{ width: "auto", height: "70px", marginLeft: "0.3rem", marginRight: "0.5rem", display: "block", borderRadius: "0.2rem" }} />
            </div>
            <RPMText priscmicField={tagline} variant="p" sx={{ fontSize: { xs: "1rem", sm: "1rem", md: "1.1rem" }, lineHeight: { xs: "1rem", sm: "1.1rem", md: "1.1rem" }, fontWeight: "500", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", margin: "0 !important" }} />
          </div>
        </Box>
      )
    }
    else {
      return (
        <Box sx={{ px: { xs: "0", sm: "1rem" }, pt: "1rem", color: color }}>
          <div style={{ backgroundColor: theme.palette.background.light, border: "1px solid " + theme.palette.background.border, borderRadius: "0.3rem", width: "100%", height: "100%", color: "#333", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "stretch", fontSize: "16px", fontWeight: "500", padding: "0.8rem", gap: "1rem", minHeight: "84px", marginTop: "1rem", maxWidth: "500px", boxShadow: "10px 10px 60px 0px rgba(0,0,0,0.1)" }}>
            <RPMText priscmicField={tagline} variant="p" sx={{ fontSize: { xs: "1rem", sm: "1rem", md: "1.1rem" }, lineHeight: { xs: "1rem", sm: "1.1rem", md: "1.1rem" }, fontWeight: "500", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", margin: "0 !important" }} />
          </div>
        </Box>
      )
    }
  }

  const animInitiated = useRef(false);

  useEffect(() => {
    const tl = gsap.timeline({
      paused: true,
      defaults: {
        duration: 2.5,
        ease: 'power2.out',
        delay: 1,
      },
    });

    tl.to('.rainbow-text', {
      backgroundPosition: '0 100%'
    });

    tl.play();

    const tr = ScrollTrigger.create({
      trigger: '#transparentTop',
      start: 'bottom 80%',
      end: 'top 20%',
      onEnterBack: () => {
        console.log("onEnterBack");
        if (!tl.isActive()) {
          tl.restart();
        }
      },
      markers: false,
      invalidateOnRefresh: true,
    });

    return () => {
      tl.kill();
      tr.kill();
    };
  }, []);


  const getButtonLink = (linkData) => {
    if (!linkData) return null;
    if (linkData.includes('#')) {
      return { url: "#" + linkData.split("#")[1], type: "internal" };
    } else {
      return { url: linkData };
    }
  }

  const buttonLink = getButtonLink(sliceData.link.url);
/*
  useEffect(() => {
    
    const preloadImage = (src) => {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = src;
      document.head.appendChild(link);
    };

    preloadImage(heroImage);

  }, [sliceData]);
*/

  return (
    <ThemeProvider theme={theme}>
      <Stack className='slice' id="topSlice" direction="column"
        spacing={0} sx={{
          overflow: "hidden", width: "100%", position: { sm: "relative", md: "fixed" }, minHeight: "100%",
          zIndex: 0, backgroundColor: "white",
        }}
        alignItems={{ xs: 'center', sm: 'center', md: 'center' }}
        justifyContent="center"
      >
        <Stack id="topMainContent" px={6} direction={{ sm: "column", md: "row" }} spacing={{ xs: 0, sm: 0, md: 0 }} sx={{ width: "100%", position: "relative", alignItems: { xs: "center", sm: "center", md: "center" }, mt: { xs: "5vh", sm: "6vh", lg: "0" }, mb: { xs: "0", sm: "0", md: "35vh", lg: "15vh" }, mx: { xs: "2rem", sm: "2rem", md: "5vw" } }}>
          <Stack
            direction={"column"}
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent='flex-end'
            spacing={0}
            ref={containerRef}
            flex={{ sm: "0", md: "0.8" }}
          >
            <Box
              style={{ position: "relative", marginLeft: "auto" }}
              sx={{ mt: { sm: "2rem", md: "0rem" }, px: { xs: "4rem", sm: "1rem" }, maxWidth: "600px" }}
            >
              <Box
                id="topText"
                className='rainbow-text'
              >
                <RPMText priscmicField={sliceData.title1} variant="h1Bold" sx={{
                  p: "0px",
                  color: color,
                  mb: "0px",
                  textAlign: "left",
                  alignContent: "flex-start",
                }} />
                <RPMText priscmicField={sliceData.subtitle} variant="h2" elementRenderType={"h2"}
                  sx={{
                    pl: "1rem",
                    color: color,
                    letterSpacing: "0.00938em",
                    lineHeight: "1.167",
                    fontSize: '1rem',
                    fontFamily: "Kanit, Roboto, Helvetica, Arial, sans-serif",
                    textTransform: "inherit",
                    fontWeight: 400,
                    marginTop: "0rem",
                    fontSize:
                    {
                      xs: "1.21rem",
                      sm: "1.9rem",
                      md: "1.94rem",
                    },
                    textAlign: "left",
                    alignContent: "flex-start",
                  }} />

              </Box>


              <Tagline />

              {buttonLink && (
                <Box sx={{ px: { xs: "0", sm: "1rem" }, pt: "0", color: "color" }}>
                  <RPMButton link={buttonLink} linktext={sliceData?.linktext[0].text} size="large" />
                </Box>
              )}


            </Box>
          </Stack>

          {heroImage &&
            <Box id="topImage" ref={topimgContainerRef} sx={{ position: "relative", zIndex: "1002", textAlign: { sm: "center", md: "left" }, flex: { sm: "0", md: "0.7" }, marginLeft: "1rem !important", mt: { xs: "8vh !important", sm: "8vh !important", lg: "0vh !important" }, pointerEvents: "none", img: { minHeight: { xs: "350px", sm: "450px", md: "550px", lg: "650px" } } }}>
              <img id="topiPhone" alt="Cardcam - Digitala visitkort på iPhone med Apple Wallet" ref={ccRef} src={heroImage} style={{ height: "20vw", width: "auto", aspectRatio:{heroImageAspectRatio}, maxHeight: "600px", zIndex: "2000", position: "relative", pointerEvents: "none", transition: "all 0.5s ease-in-out" }} loading='eager' />
            </Box>
          }
        </Stack>

        {true &&
          <Stack direction="row" spacing={3} style={{}} sx={{
            gap: "1rem",
            '@media (max-height: 760px)': {
              md: {
                display: "none"
              }
            }, position: { sm: "static", md: "fixed" }, paddingTop: { xs: "80px", sm: "100px", md: "0px" }, paddingBottom: { xs: "30px", sm: "40px", md: "20px" }, bottom: "10px", width: "100%", zIndex: "1000", alignItems: "flex-start", justifyContent: "center"
          }}>
            <Stack direction="row" spacing={0} sx={{ position: "relative", zIndex: "1000", alignItems: "center", justifyContent: "center", gap: "1rem", margin: "0 1rem" }}>
              <img src={apple} alt="Apple Wallet icon" style={{ height: "32px", width: "auto", zIndex: "1000", right: "0px", top: "0px" }} />
              <Typography variant="p" sx={{ color: "#444", zIndex: "1000", fontWeight: "500", mb: 0 }}>Apple Wallet®</Typography>
            </Stack>
            <Stack direction="row" spacing={0} sx={{ position: "relative", zIndex: "1000", alignItems: "center", justifyContent: "center", gap: "1rem", margin: "0 1rem" }}>
              <img src={android} alt="Google Wallet icon" style={{ height: "32px", width: "auto", zIndex: "1000", left: "0px", top: "0px" }} />
              <Typography variant="p" sx={{ color: "#444", zIndex: "1000", fontWeight: "500", mb: 0 }}>Google Wallet™</Typography>
            </Stack>

          </Stack>
        }

      </Stack>

    </ThemeProvider >
  );
};

export default TopHeroSlice;