import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Alert, AlertTitle, AppBar, Box, Button, Collapse, Dialog, DialogContent, DialogTitle, Divider, Drawer, IconButton, Link, Stack, ThemeProvider, Toolbar, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { generateTheme } from '../themes';
import { RPMButton } from './subComponents/RPMButton';
import { RPMText } from './subComponents/RPMText';

const Menu = ({ sliceData, messageId = null }) => {



    const theme = generateTheme(sliceData?.primary?.color_theme);

    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const [openDialog, setOpenDialog] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const appBarHeight = '64px'; // Example height of your AppBar
    const appBarZIndex = 11000; // Default AppBar z-index in Material-UI
    const drawerZIndex = appBarZIndex - 100; // Ensure this is lower than the AppBar's z-index

    const messageData = sliceData && sliceData.items.find(item => item.message_id === messageId);
    const isAlert = messageData && messageData.message_type === 'alert';
    const isDialog = messageData && messageData.message_type === 'dialog';

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        if (isDialog) {
            setOpenDialog(true);
        }
        if (isAlert) {
            setOpenAlert(true);
        }
    }, [messageId, isDialog, isAlert])

    if (!sliceData) {
        return null;
    }

    const { logo, links, cta_link, cta_link_title } = sliceData.primary;

    // Handling Dialog open/close
    const handleDialogOpen = () => setOpenDialog(true);
    const handleDialogClose = () => setOpenDialog(false);


    // Render message content
    const renderMessage = (message) => {

        if (message[0]?.spans) {
            //iterate over the spans and fixPrismicLinks 
            message[0].spans.forEach(span => {
                if (span?.data?.url) {
                    console.log("span", span)
                    span.data.url = fixAnchorLinks(span.data.url);
                }
            });

        }

        return <RPMText priscmicField={message} sx={{ mb: 0 }} />
    };

    // Call this function to open the drawer and prevent body scroll
    const openDrawer = () => {
        //  setScrollPosition(window.scrollY); // Save current scroll position

        console.log("scrollPosition", scrollPosition)
        setIsMenuOpen(true);
    };

    // Call this function to close the drawer and allow body scroll
    const closeDrawer = () => {
        //  window.scrollTo(0, scrollPosition); // Restore scroll position

        // document.body.style.overflow = ''; // Restore original overflow value
        setIsMenuOpen(false);
    };

    //console.log("D<ta", sliceData)

    if (sliceData.slice_type !== "menu") {
        return null;
    }

    const navigateToHome = () => {
        window.location.href = "/";
    }

    const goToAnchor = (anchor) => {
        //strip the # from the anchor
        anchor = anchor.replace('#', '');

        const element = document.getElementById(anchor);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
    }

    const fixAnchorLinks = (link) => {
        console.log("fixAnchorLinks", link)
        let navlink = link;
        let isAnchor = false;

        if (link.includes('https://#')) {
            navlink = link.replace('https://#', '#');
            isAnchor = true;
        }
        return { navlink, isAnchor };
    }

    const NavLink = ({ link, index }) => {
        const { navlink, isAnchor } = fixAnchorLinks(link.spans[0].data.url);

        console.log("NavLink", navlink, isAnchor)

        console.log("index", index)

        //if (isAnchor) we should call the hook here??
        return (
            <Link
                key={index}
                sx={{
                    color: 'black', display: 'block', textDecoration: 'none', // This removes the underline by default
                    '&:hover': {
                        textDecoration: 'underline', // This adds the underline on hover
                    },
                }}
                style={{ marginTop: "0.5rem", fontWeight: "400 !important" }}
                href={isAnchor ? null : navlink}
                onClick={() => isAnchor ? goToAnchor(navlink) : null}
                variant='button'
            >
                {link.text}
            </Link>
        )

    }


    const logoAspects = logo.dimensions.width / logo.dimensions.height;

    return (
        <ThemeProvider theme={theme}>
            <AppBar id="topNavigation" position="sticky" elevation={0} sx={{
                backgroundColor: "rgba(255,255,255,0.9)",
                backdropFilter: "saturate(180%) blur(20px)",
                width: "100vw",
                zIndex: appBarZIndex,
            }}>
                <Toolbar sx={{ display: 'flex', justifyContent: isMobile ? 'space-between' : 'flex-start', alignItems: 'center', width: "100%", minHeight: appBarHeight }}>
                    <Box component="img" src={logo.url} alt={logo.alt || "Cardcam"} sx={{ height: `${logo.dimensions.height}px`, cursor: "pointer", aspectRatio: `${logoAspects}` }} onClick={() => goToAnchor("root")} />
                    {!isMobile && (
                        <Stack direction="row" spacing={2} alignItems="center" sx={{ width: "100%" }}>
                            <Stack direction="row" alignItems='center' spacing={4} ml={4} sx={{ flexGrow: 1 }} >
                                {links.map((link, index) => (
                                    <NavLink link={link} index={index} key={"tb" + index} />
                                ))}
                            </Stack>
                            <div style={{ flexGrow: 1 }} />
                            {cta_link_title && (
                                <>
                                    <RPMButton link={cta_link} linktext={cta_link_title[0].text} type='outlined' sx={{ maxWidth: "fit-content" }} size="small" />
                                </>
                            )}
                        </Stack>
                    )}
                    {isMobile && (
                        <>
                            {isMenuOpen ? (
                                <IconButton onClick={() => closeDrawer()}>
                                    <CloseIcon />
                                </IconButton>
                            ) : (
                                <IconButton
                                    size="large"
                                    edge="start"
                                    aria-label="menu"
                                    sx={{ color: "#000" }}
                                    onClick={() => openDrawer()}
                                >
                                    <MenuIcon />
                                </IconButton>
                            )}
                        </>
                    )}
                </Toolbar>
            </AppBar>
            {/* Render Alert */}
            {isAlert && (
                <Collapse in={openAlert}>
                    <Alert severity="warning" sx={{ position: 'fixed', top: appBarHeight, width: '100%', zIndex: appBarZIndex + 1 }}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpenAlert(false);
                                }}
                            >

                                <CloseIcon fontSize="inherit" />
                            </IconButton>}
                    >
                        <AlertTitle>Information</AlertTitle>
                        {renderMessage(messageData.message)}
                    </Alert>
                </Collapse>
            )
            }

            {/* Render Dialog */}
            {
                isDialog && (
                    <Dialog open={openDialog} onClose={handleDialogClose} sx={{ zIndex: appBarZIndex + 1, position: 'fixed' }}>
                        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                            Information
                        </DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={handleDialogClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <DialogContent dividers>

                            <Box>
                                {renderMessage(messageData.message)}
                            </Box>
                        </DialogContent>
                        <RPMButton action={handleDialogClose} linktext={"OK"} type='text' sx={{ maxWidth: "100%" }} />
                    </Dialog>
                )
            }
            <Drawer
                anchor="top"
                open={isMenuOpen}
                transitionDuration={{ enter: 500, exit: 200 }}
                onClose={() => closeDrawer()}
                sx={{ display: { xs: 'flex', lg: 'none' }, position: 'absolute', zIndex: drawerZIndex }}
                PaperProps={{
                    sx: {
                        backgroundColor: "background.primary",
                        position: 'fixed',
                        minWidth: "40%",
                        top: appBarHeight,
                        zIndex: drawerZIndex,
                        backgroundColor: "rgba(255,255,255,0.9)",
                        backdropFilter: "saturate(180%) blur(20px)",
                        pt: 4,
                        pb: 2
                    }
                }}
            >

                <Stack direction="column" spacing={2} px={3}>
                    {links.map((link, index) => (
                        <NavLink link={link} index={index} key={"drw" + index} />


                    ))}
                    <Divider sx={{ bgcolor: "secondary.light", my: 2 }} />
                    {cta_link_title && (
                        <Link
                            variant="linkMenu"
                            color={"text.primary"}
                            href={cta_link.url}
                            target={cta_link.target || "_self"}
                            sx={{ my: 1, mx: 1.5, textDecoration: 'none' }}
                            onClick={() => closeDrawer()}
                        >
                            {cta_link_title[0].text}
                        </Link>
                    )}
                </Stack>
            </Drawer>
        </ThemeProvider >
    );
};
export default Menu;
