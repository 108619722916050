import { Link, Stack, Typography } from "@mui/material";
import { PrismicRichText } from "@prismicio/react";
import CircleIcon from '@mui/icons-material/Circle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';


export const RPMText = ({ priscmicField, variant, elementRenderType, sx = {} }) => {

    if (!priscmicField || typeof priscmicField !== "object") return null;

    const defaultSx = {
        color: "text.primary",
        a: {
            color: "text.link",
            cursor: "pointer",
            textDecoration: "underline",
            "&:visited": { color: "text.link_visited" },
            "&:hover": { color: "text.link_hover" },
            transition: "color 0.3s ease-in-out",
        },
        button: {
            color: "text.link",
            cursor: "pointer",
            fontSize: "1rem",
            fontWeight: "bold",
            textDecoration: "underline",
            "&:visited": { color: "text.link_visited" },
            "&:hover": { color: "text.link_hover" },
            transition: "color 0.3s ease-in-out",
        }

    }

    sx = { ...defaultSx, ...sx };

    const goToAnchor = (anchor) => {
        //strip the # from the anchor

        console.log("goToAnchor", anchor);
        anchor = anchor.replace('#', '');

        const element = document.getElementById(anchor);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
    }

    // Function to fix anchor links
    const fixAnchorLinks = (data) => {
        if(!data) return null;
        
        return data.map(item => {
            if (item.type === 'paragraph' && item.spans) {
                return {
                    ...item,
                    spans: item.spans.map(span => {
                        if (span.type === 'hyperlink' && span.data.url.includes('#')) {
                            return {
                                ...span,
                                data: {
                                    ...span.data,
                                    url: "#" + span.data.url.split('#')[1],
                                }
                            };
                        }
                        return span;
                    })
                };
            }
            return item;
        });
    };

    // Process the priscmicField data
    const processedField = fixAnchorLinks(priscmicField);

    const Hyperlink = ({ node, children }) => {
        // Check if the URL is external
        const isExternal = node.data.url.startsWith('http://') || node.data.url.startsWith('https://');

        // Fix the URL if it starts with 'https://#'
        const fixedUrl = node.data.url.startsWith('https://#') ? node.data.url.replace('https://#', '#') : node.data.url;


        console.log("Hyperlink", fixedUrl);

        if(fixedUrl.startsWith('#')) {
            return (
                <Link href={null} component="button" onClick={() => goToAnchor(fixedUrl)} sx={sx.a} style={{ textDecoration: "none", display: "inline-flex", alignItems: "center" }}>
                    {children}
                </Link >
            );
        }

        return (
            <Link href={fixedUrl} target={isExternal ? '_blank' : '_self'} sx={sx.a} style={{ textDecoration: "none", display: "inline-flex", alignItems: "center" }}>
                    {children}
                    {isExternal && <OpenInNewIcon sx={{ ml: 1, fontSize: "1em" }} />}
            </Link >
        );
    };


    return (
        <PrismicRichText
            field={processedField}
            components={{
                heading1: ({ children }) => <Typography variant={variant ?? "h1"} component={elementRenderType ?? "h1"} sx={sx}>{children}</Typography>,
                heading2: ({ children }) => <Typography variant={variant ?? "h2"} component={elementRenderType ?? "h2"} sx={sx}>{children}</Typography>,
                heading3: ({ children }) => <Typography variant={variant ?? "h3"} component={elementRenderType ?? "h3"} sx={sx}>{children}</Typography>,
                paragraph: ({ children }) => <Typography variant={variant ?? "p"} component={elementRenderType ?? "p"} paragraph
                    sx={sx}
                >
                    {children}</Typography>,
                strong: ({ children }) => <Typography variant={variant ?? "span"} fontWeight="bold" component={"span"} sx={sx}>{children}</Typography>,
                listItem: ({ children }) => <li><Stack direction="row" alignItems={"center"} p={0.5}><CircleIcon sx={{ fontSize: "0.5em" }} /><Typography variant={variant ?? "p"} component={elementRenderType ?? "p"} mb={0} ml={1} paragraph>{children}</Typography></Stack></li>,
                hyperlink: Hyperlink
            }}
        />
    );
};
