import './App.css';
import CookieOutlinedIcon from '@mui/icons-material/CookieOutlined';
import { Box, Stack, ThemeProvider } from '@mui/material';
import Container from '@mui/material/Container';
import { usePrismicDocumentByUID } from '@prismicio/react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect } from 'react';
import Menu from './components/Menu';
import logo from './assets/cardcam-logo-pos.svg';
import TopHeroSlice from './components/TopHeroSlice';
import { generateTheme } from "./themes";
import { SHOW_HIDDEN_SLICES } from './config';
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import { useLocation } from 'react-router-dom';
import MetaTagsHelper from './components/utils/MetaTagsHelper';
import TopSubpageSlice from './components/TopSubpageSlice';

gsap.registerPlugin(ScrollTrigger);

export const getLink = (linkData) => {

  if (!linkData) return null

  if (linkData.includes('#')) {
    return "#" + linkData.split("#")[1]
  } else {
    return linkData
  }
}

const KeyFeaturesSlice = React.lazy(() => import('./components/KeyFeaturesSlice'));
const TextImageSlice = React.lazy(() => import('./components/TextImageSlice'));
const TextTwoCols = React.lazy(() => import('./components/TextTwoCols'));
const SellingPointsSlice = React.lazy(() => import('./components/SellingPointsSlice'));
const PriceSlice = React.lazy(() => import('./components/PriceSlice'));
const ContactSlice = React.lazy(() => import('./components/ContactSlice'));
const CustomerLogos = React.lazy(() => import('./components/CustomerLogos'));
const CustomerStoriesSlice = React.lazy(() => import('./components/CustomerStoriesSlice'));
const Faq = React.lazy(() => import('./components/Faq'));
const FaqShortSlice = React.lazy(() => import('./components/FaqShortSlice'));
const ImageFullwidth = React.lazy(() => import('./components/ImageFullwidth'));
const PricingPlanSlice = React.lazy(() => import('./components/PricingPlanSlice'));
const FooterSlice = React.lazy(() => import('./components/FooterSlice'));
const CallToActionSlice = React.lazy(() => import('./components/CallToActionSlice'));
const InfoGridSlice = React.lazy(() => import('./components/InfoGridSlice'));


function App({ pageId }) {

  const path = useLocation().pathname.split("/")[1];

  const defaultDocumentUID = "the-start-page";

  const theme = generateTheme("lightTheme");
  const [messageId, setMessageId] = React.useState(null);

  let documentType = "homepage";
  let documentUID = defaultDocumentUID;

  if (path !== "") {
    documentType = "page";
    documentUID = path;
  }

  //const [firstDocument] = useFirstPrismicDocument()
  const [prismicPage, { state }] = usePrismicDocumentByUID(documentType, documentUID)

  useEffect(() => {
    if (prismicPage) {
      //console.log(prismicPage)
      //console.log("prismicPage in hook", prismicPage)

    }
    else {
      //console.log("prismicPage FAIL in hook", prismicPage)
    }

  }, [prismicPage])

  useEffect(() => {
    if (state === "failed") {
      window.location.href = "/"
    }
  }, [state])


  /**
   * Get the message id from the url and set it in state, will be used to show the correct message in the Menu component
   */
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const errorParam = urlParams.get('error');

    if (errorParam) {
      setMessageId(errorParam);
    }

    handleAcceptCookie();
  }, []);


  const generateContent = () => {
    if (!prismicPage) {
      return null;
    }

    const componentMap = {
      "key_features": KeyFeaturesSlice,
      "header__image_and_text": TextImageSlice,
      "header__video_and_text": TextImageSlice,
      "text_two_cols": TextTwoCols,
      "selling_points": SellingPointsSlice,
      "pricing_iteration_1": PriceSlice,
      "contact_form": ContactSlice,
      "prices_cardcam": PriceSlice,
      "customer_logos": CustomerLogos,
      "customer_stories": CustomerStoriesSlice,
      "faq_section": Faq,
      "faq_short": FaqShortSlice,
      "imagefull": ImageFullwidth,
      "prices": PricingPlanSlice,
      "footer": FooterSlice,
      "call_to_action": CallToActionSlice,
      "infogrid": InfoGridSlice
    };

    // find the price slice and check if it is visible
    const priceSlice = prismicPage.data.body.find(slice => slice.slice_type === "prices_cardcam")
    const priceSliceVisible = priceSlice?.primary?.slice_visible;
    const page_type = prismicPage?.data?.page_type;

    return prismicPage.data.body.map((sliceData, index) => {
      const currentDirection = index % 2 !== 1 ? "row" : "row-reverse";
      const SliceComponent = componentMap[sliceData.slice_type];

      const sliceVisible = sliceData?.primary?.slice_visible;

      if (sliceVisible === false && SHOW_HIDDEN_SLICES === false) {
        return null;
      }

      let anchor = null;

      if (sliceData?.primary?.anchor) {
        if (sliceData?.primary?.anchor.length > 0) {
          if (sliceData?.primary?.anchor[0].text) {
            anchor = sliceData?.primary?.anchor[0].text.replace("#", "");
          }
        }
      }

      return SliceComponent ?
        <React.Suspense key={index.toString()} fallback={<div>Loading...</div>}>
          <Box id={anchor} className="slice-container" sx={{ borderBottom: "1px solid #0000ff", borderColor: "background.border" }}>
            <SliceComponent
              sliceData={sliceData}
              index={index}
              direction={currentDirection}
              pricesVisible={sliceData.slice_type === "contact_form" ? priceSliceVisible : undefined}
              pageType={page_type}
            />
          </Box>
        </React.Suspense>
        : null;
    });
  }

  const handleAcceptCookie = () => {

    // Check if on localhost and return early if true
  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    return;
  }

    // Check if the GTM script tag already exists
    const existingScript = document.querySelector('script[src*="googletagmanager.com/gtm.js"]');
    if (existingScript) {
      console.log("GTM script already exists");
      return; // GTM script already exists, so exit the function
    }
    // Create the Google Tag Manager script tag
    const script = document.createElement('script');
    script.innerHTML = `(function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-MPKBRBG');`;
    // Append the script tag to the head of the document
    document.head.appendChild(script);
    console.log("Added GTM script tag to head of document");
  };

  const getMenuData = () => {
    //iterate over all slices and find the menu slice
    const menuSlice = prismicPage?.data?.body?.find(slice => slice.slice_type === "menu")
    return menuSlice;
  }

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  const menuData = getMenuData();

  if (prismicPage) {
      if (prismicPage?.data?.page_type === "subpage") {
      return (
        <ThemeProvider theme={theme}>
          <MetaTagsHelper prismicPage={prismicPage} />
          <div className="App">
            <Container disableGutters maxWidth={false} sx={{backgroundColor:"background.paper", minHeight: "100dvh"}} >
              <Menu sliceData={getMenuData()} messageId={messageId} />
              <Stack direction="column" spacing={0} sx={{ display: "flex", justifyContent: "center", alignItems: "center", maxWidth: { sm: "100%", lg: "1000px" }, margin: "auto", padding: "0px 0px 0px 0px", minHeight: "100dvh"}}>
                <TopSubpageSlice sliceData={prismicPage?.data} />
                {generateContent()}
              </Stack>
            </Container>
          </div>
        </ThemeProvider>
      )
    }

    return (
      <ThemeProvider theme={theme}>
        <MetaTagsHelper prismicPage={prismicPage} />
        <div className="App">
          <Container disableGutters maxWidth={false}>
            <Menu sliceData={getMenuData()} messageId={messageId} />

            <TopHeroSlice sliceData={prismicPage?.data} />

            <Box id="transparentTop" sx={{ backgroundColor: "#ff00000", height: { md: "100%", md: "100vh" }, zIndex: -200, position: "relative" }} />
            {generateContent()}
          </Container>
        </div>
      </ThemeProvider>

    )
  } else {
    return (
      <div style={{ width: "100%", height: "100%", backgroundColor: "#fff" }}>
        <img src={logo} alt="Cardcam logo" style={{ width: "auto", height: "auto", display: "block", maxHeight: "30px", margin: "17px 24px" }} />
      </div>
    )
  }
}

export default App;
